import "./presentation.css"
import "../shared.css"
import "./side-menu.scss"

import SideMenu from "./SideMenu"
import RegistrationStep1Form from "./Registration/RegistrationStep1Form.svelte"
import Flickity from "flickity"
import 'flickity-imagesloaded'
import FaqContainer from "./Faq/FaqContainer.svelte"
import ContactForm from "./Contact/ContactForm.svelte"
import PasswordRecoveryForm from "./User/PasswordRecoveryForm.svelte"
import PasswordResetForm from "./User/PasswordResetForm.svelte"
import {SvelteToast} from "@zerodevx/svelte-toast"
import PricingSelectionType from "@shared/Form/PricingSelectionType/PricingSelectionType.svelte"


new SvelteToast({
    target: document.body,
})

const sideMenuRoot = document.querySelector('.msd-menu')
if (sideMenuRoot) {
    new SideMenu(sideMenuRoot)
}


// const flashMessagesModal = document.querySelector('#flashMessageModal')
// if (flashMessagesModal !== null) {
//     setTimeout(() => {
//         Modal.getOrCreateInstance(flashMessagesModal).show()
//     }, 300)
// }

const registrationStep1 = document.querySelector(".msd-registration-form-step1")
if (registrationStep1 !== null) {
    const formEntry = JSON.parse(registrationStep1.getAttribute("data-form-entry") ?? "")
    registrationStep1.removeAttribute("data-form-entry")

    new RegistrationStep1Form({
        target: registrationStep1,
        props: {
            formEntry,
        },
    })
}

const hpBanner = document.querySelector(".msd-homepage-slider")
if (hpBanner !== null) {
    new Flickity(hpBanner, {
        cellAlign: 'center',
        wrapAround: true,
        autoPlay: 10000,
        freeScroll: false,
        watchCSS: false,
        selectedAttraction: 0.007,
        friction: 0.4,
        prevNextButtons: false,
        pageDots: false,
        cellSelector: 'img',
        on: {
            change: (index) => {
                if(index === 1) {
                    document.querySelector(".msd-section-homepage-slider-text")?.setAttribute("data-text-color", "white")
                    document.querySelectorAll(".msd-intro-scroll-indicator").forEach(item => item.setAttribute("data-text-color", "white"))
                    document.querySelectorAll(".msd-header-logo").forEach(item => item.setAttribute("data-text-color", "white"))
                } else {
                    document.querySelector(".msd-section-homepage-slider-text")?.removeAttribute("data-text-color")
                    document.querySelectorAll(".msd-intro-scroll-indicator").forEach(item => item.removeAttribute("data-text-color"))
                    document.querySelectorAll(".msd-header-logo").forEach(item => item.removeAttribute("data-text-color"))
                }
            }
        }
    })
}

const pricingSelection = document.querySelector(".msd-pricing-calculator")
if (pricingSelection !== null) {
    const formEntry = JSON.parse(pricingSelection.getAttribute("data-form-entry") ?? "")
    pricingSelection.removeAttribute("data-form-entry")

    new PricingSelectionType({
        target: pricingSelection,
        props: {
            entry: formEntry,
        },
    })
}

const faqContainer = document.querySelector(".msd-faq-container")
if (faqContainer !== null) {
    fetch(faqContainer.getAttribute("data-route") as string).then(r => r.json()).then(response => {
        new FaqContainer({
            target: faqContainer,
            props: response
        })
    })
}

const contactForm = document.querySelector(".msd-contact-form-container")
if (contactForm !== null) {
    const formEntry = JSON.parse(contactForm.getAttribute("data-form-entry") ?? "")
    contactForm.removeAttribute("data-form-entry")

    new ContactForm({
        target: contactForm,
        props: {
            formEntry,
        },
    })
}

const passwordRecoveryForm = document.querySelector(".msd-password-recovery-form")
if (passwordRecoveryForm !== null) {
    const formEntry = JSON.parse(passwordRecoveryForm.getAttribute("data-form-entry") ?? "")
    passwordRecoveryForm.removeAttribute("data-form-entry")

    new PasswordRecoveryForm({
        target: passwordRecoveryForm,
        props: {
            formEntry,
        },
    })
}

const passwordResetForm = document.querySelector(".msd-password-reset-form")
if (passwordResetForm !== null) {
    const formEntry = JSON.parse(passwordResetForm.getAttribute("data-form-entry") ?? "")
    passwordResetForm.removeAttribute("data-form-entry")

    new PasswordResetForm({
        target: passwordResetForm,
        props: {
            formEntry,
        },
    })
}