<script lang="ts">

    import {FormEntry} from "@shared/Form/interface"
    import FormChildren from "@shared/Form/FormChildren.svelte"
    import axiosClient from "@utils/axiosClient"
    import {removeErrorsFromFormEntry, scrollToFirstError} from "@shared/Form/utils"

    export let formEntry: FormEntry

    let formEl: HTMLFormElement
    let loading = false

    const handleSendForm = async (): Promise<void> => {
        if (loading) {
            return
        }

        formEntry = removeErrorsFromFormEntry(formEntry)

        loading = true

        axiosClient.postForm(formEntry.action ?? (new URL(window.location)).toString(), new FormData(formEl))
        .then(response => {
            if (response.data.formEntry) {
                formEntry = response.data.formEntry
            }
        })
        .finally(() => {
            loading = false
            scrollToFirstError()
        })
    }
</script>

<form bind:this={formEl}
      class="flex flex-col max-w-sm gap-6"
      id={formEntry?.id} name={formEntry?.fullName}
      on:submit|preventDefault={handleSendForm}
>
    <FormChildren form={formEntry}/>

    <button class="{loading ? 'bg-neutral-600' : 'bg-primary hover:bg-black'} rounded-lg px-4 lg:px-8 py-2.5 w-full transition-colors group" on:click|preventDefault={handleSendForm}>
        <b class="tracking-wider text-md {loading ? 'text-white' : 'text-neutral-800'} transition-colors group-hover:text-white">{loading ? "Zpracováváme" : "Odeslat email pro obnovu hesla"}</b>
    </button>
</form>